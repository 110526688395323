import { Result, TenantInfoOnProductDetailShowVO, TransactionTenantInfoVO } from '@/models/product'
import HttpRequest from '@/plugins/http'

const request = new HttpRequest({
  baseURL: '/transaction/transaction-mall-service',
})

const axios = request.request()

// 获取商品信息
export async function getProductInfo(params: { id: string }) {
  return axios.get('/product/' + params.id + '/v1')
}
// 添加购物车
export async function addProductToCart(params: { skuId: string; quantity: string; qty: string }) {
  return axios.post('/order/cart/add/v1', params)
}
// 获取购物车列表
export async function getCartList(params: { pageSize: number; page: number }) {
  return axios.post('order/cart/list/v1', params)
}
// 获取购物车数量
export async function getCartCount(params: {} = {}) {
  return axios.post('/order/cart/count/v1', params)
}
// 购物车更新数据
export async function cartUpdate(params: { id: string; qty: number }) {
  return axios.post('order/cart/updateNum/v1', params)
}
// 购物车移除数据
export async function cartDelete(params: { idStrList: string[] }) {
  return axios.post('order/cart/delete/v1', params)
}
// 获取租户信息
export async function getTenantInfo(params: TransactionTenantInfoVO) {
  return axios.post('tenant/show/list/v1', params)
}
// 获取租户信息
export async function getTenantInfoV2(params: { tenantCode: string; productId: string }) {
  return axios.post('tenant/show/list/v2', params) as Promise<
    Result<TenantInfoOnProductDetailShowVO>
  >
}
