import { getUimAccessToken } from '@/api/userApi'
import { GIOLoginRedirectEvent } from './gio'
import { isWeChatBrowser } from './utils'
const uimVersion = process.env.VUE_APP_UIM_VERSION
const envValue = process.env.NODE_ENV

/**
 * 
 * @param {*} redirectPath
 * @param {*} lang
 * @param {{v?: any; loginTypeForGio: string}} param2
 */
export function gotoUimLogin (redirectPath, lang, { v, loginTypeForGio } = {}) {
  if (uimVersion === '1' || v === 'v1') {
    // redirectPath需要过滤掉code，避免UIM登录跳转回时带有两个code参数，导致获取用户信息错误
    // 开发注意：router query参数不能使用code作为key
    const newRedirectPath = redirectPath.replace(/[?&]code=[^&]*&?/g, '')
    gotoLoginUrl(newRedirectPath, lang, null, loginTypeForGio)
  } else {
    // UIM新版登录需要过滤accessToken
    const newRedirectPath = redirectPath.replace(/[?&]accessToken=[^&]*&?/g, '')
    console.log('=========newRedirectPath',window.location.href)
    // process.env.VUE_APP_DEVELOPMENT_URL
    // process.env.VUE_APP_SERVER_URL
     // dev模拟siemens-x ********* 登录  siemens-x
     var isSiemensx = /^(www.)?siemens-x/g.test(window.location.hostname.toLowerCase())
     let envUrl = isSiemensx?'https://www.siemens-x.com.cn/':process.env.VUE_APP_SERVER_URL
    getUimAccessToken({ redirectPath: (envValue === 'development' ? window.location.href : envUrl) + decodeURIComponent(decodeURIComponent(newRedirectPath)) }).then((res) => {
      if (res && res.code === 200) {
        window.console.log(res)
        localStorage.setItem('accessToken', res.data.accessToken)
        gotoLoginUrl(null, lang, res.data.accessToken, loginTypeForGio)
      }
    })
  }
}

export function gotoLoginUrl (redirectPath, lang, accessToken, loginTypeForGio) {
  let strUrl = ''
  if (accessToken) {
    strUrl = process.env.VUE_APP_UIM_BASE_URL_V2 + process.env.VUE_APP_UIM_PATH_V2 + '?accessToken=' + accessToken
  } else {
    strUrl = process.env.VUE_APP_UIM_BASE_URL + process.env.VUE_APP_UIM_PATH
  }
  if (lang) {
    strUrl += '&lang=' + lang
  }
  if (redirectPath) {
    strUrl += '&redirectPath=' + (accessToken ? encodeURIComponent(process.env.VUE_APP_SERVER_URL) : '') + redirectPath
  }
  // GIO埋点
  loginTypeForGio && GIOLoginRedirectEvent(loginTypeForGio)
  if (process.env.VUE_APP_SENDBOX_LOGIN_SWITCH === 'close' || isWeChatBrowser()) {
    sessionStorage.removeItem('loginRedirectUrl')
    window.location.href = strUrl
  } else {
    sessionStorage.setItem('loginRedirectUrl', encodeURIComponent(strUrl))
    window.location.href = (window.location.origin + '/login')
  }
}

export function getUserInfo () {
}
