/* eslint-disable no-unused-expressions */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import content from '@/assets/json/content.json'
import contentEn from '@/assets/json/content-en.json'
import registerSvgIcon from '@/icon'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import '@/assets/style/global.scss'
import { pointEvent } from './api/commonApi'
import i18n from './i18n/index'
import store from './store'
// import Vconsole from 'vconsole'
import commonMessage from './components/commonMessage/index'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
// 判断http时刷新跳转到https
if (process.env.NODE_ENV !== 'development') {
  if (window.location.protocol === 'http:') {
    window.location.reload()
  }
}

// 一个函数，用于更新 messages
export function updateMessages(newZhMessages, newEnMessages, str) {
  const enList = newEnMessages || {}
  const ZhList = newZhMessages || {}
  Object.keys(enList).forEach((el) => {
    i18n.global.getLocaleMessage('en')[str][el] = enList[el]
  })
  Object.keys(ZhList).forEach((el) => {
    i18n.global.getLocaleMessage('zh')[str][el] = ZhList[el]
  })
}
// create vue
const app = createApp(App)
app.use(router,VueAxios,axios, VueMeta)
app.use(store)
app.use(i18n)
app.use(vue3videoPlay)
app.config.globalProperties.$message = commonMessage

// 手机端测试工具 vConsole
// if (process.env.NODE_ENV === 'sit') {
//   let vConsole = new Vconsole()
//   app.use(vConsole)
// }
app.mount('#app')
app.directive('clickOutside', {
  beforeMount(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      // 检查点击事件是否在元素外部
      if (!(el === event.target || el.contains(event.target))) {
        // 调用方法
        binding.value?.clickOutside()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    // 清理事件监听器
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
  /* ... */
})
// 添加<svg>
registerSvgIcon(app)
// 全局变量
app.config.globalProperties.$content = content
app.config.globalProperties.$contentEn = contentEn
app.config.globalProperties.$arrCategoryObj = []
app.config.globalProperties.resetSetItem = function (key, newVal) {
  if (key === 'userInfo') {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val)

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      },
    }
    return storage.setItem(key, newVal)
  }
}

// 坑位埋点指令
app.directive('stat', {
  bind(el, binding) {
    el.addEventListener(
      'click',
      () => {
        const data = binding.value
        // let prefix = 'store'
        // if (OS.isAndroid || OS.isPhone) {
        //   prefix = 'mall'
        // }
        const param = {
          element_class_name: `${data.class_name}`,
          element_content: `${data.content}`,
          element_id: `${data.id}`,
          element_name: `${data.name}`,
          element_target_url: `${data.target_url}`,
          element_type: `${data.type}`,
          title: `${data.title}`,
          url: `${data.url}`,
          url_path: `${data.url_path}`,
        }
        pointEvent(param)
          .then((res) => {
            if (res.code === 200) {
              // console.log(res.data)
            } else {
              console.log('Error Code:', res.code + ':' + res.msg)
            }
          })
          .catch(function (error) {
            console.log('pointPage Error:', error)
          })
      },
      false
    )
  },
})

export default app
